@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
:root {
  --pri-color: #171730;
  --sec-color: #00f521;
  --ter-color: #ffffff;
  --font-color: rgb(255, 255, 255);
}
/* GAME */
.container-game {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98.5%;
}

#introduction {
  color: var(--ter-color);
  width: 300px;
  height: 120px;
  position: absolute;
  font-weight: 700;
  font-size: 1.5em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  transition: opacity 2s;
}

#restart {
  width: 200px;
  height: 200px;
  left: 20px;
  font-weight: 700;
  font-size: 1.5em;
  position: absolute;
  border-radius: 50%;
  color: var(--ter-color);
  border: 2px solid var(--font-color);
  background-color: #171730;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: none;
  cursor: pointer;
}
#gameover {
  border-radius: 20px;
  color: var(--ter-color);
  border: 2px solid var(--font-color);
  background-color: rgba(17, 75, 19, 0.1);
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  font-weight: 700;
  font-size: 2em;
  justify-items: center;
  margin-bottom: 400px;
  text-decoration: none;
  list-style: none;
  padding: 10px;
}

#perfect {
  color: var(--ter-color);
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: 0;
  font-weight: 700;
  font-size: 1.5em;
  transition: opacity 2s;
  justify-items: center;
}
#action {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 20px;
  color: var(--ter-color);
  font-weight: 700;
  font-size: 1.5em;
  border: 2px solid var(--font-color);
  background-color: #171730;
  border-radius: 50%;
}
#placar {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  position: absolute;
  padding: 25px;
  border-radius: 20px;
  right: 20px;
  color: var(--ter-color);
  font-weight: bold;
  border: 2px solid var(--font-color);
  background-color: #171730;
  font-size: 1em;
  margin-bottom: 160px;
}
#hiscore {
  text-decoration: none;
  list-style: decimal;
}
#hiscore_h {
  text-decoration: none;
  list-style: none;
  align-items: left;
}
#saveplacar {
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 20px;
  justify-content: center;
  position: absolute;
  font-weight: 800;
  font-size: 1em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  transition: opacity 2s;
  color: #fff;
  border: 2px solid var(--font-color);
  background-color: rgba(17, 75, 19, 0.5);
}
#saveBtn {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  font-weight: 700;
  font-size: 1em;
  align-items: center;
}
#nameSave {
  font-weight: 700;
  font-size: 1em;
  border-radius: 20px;
  padding: 2px;
}
#titulo {
  border-radius: 20px;
  color: var(--ter-color);
  border: 2px solid var(--font-color);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  font-weight: 700;
  font-size: 2em;
  justify-items: center;
  margin-bottom: 600px;
  text-decoration: none;
  list-style: none;
  padding: 10px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.content-game {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

@media all and (max-width: 980px) and (orientation: portrait) {
  .container-game {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  #introduction {
    font-size: 1em;
    margin-bottom: 500px;
  }

  #restart {
    width: 200px;
    height: 200px;
    margin-bottom: 150px;
  }

  #perfect {
    margin-bottom: 500px;
  }
  #action {
    width: 200px;
    height: 200px;
    margin-bottom: 150px;
  }
  #placar {
    right: 10px;
    font-size: 1em;
    margin-bottom: 250px;
  }
  #hiscore_h {
    text-decoration: none;
    list-style: none;
    align-items: left;
  }
  #saveBtn {
    font-size: 1em;
  }
  #saveplacar {
    margin-bottom: 440px;
  }
  #nameSave {
    font-weight: 500;
    font-size: 1em;
    border-radius: 20px;
    padding: 5px;
    margin-bottom: 5px;
  }
  #titulo {
    position: absolute;
    margin-bottom: 900px;
    font-size: 2em;
  }
  #gameover {
    margin-bottom: -200px;
  }
}

@media all and (max-width: 980px) and (orientation: landscape) {
  .container-game {
    border-bottom: 20px;
  }

  /* GAME */
  .container-game {
    height: 100%;
    width: 100%;
    max-width: 1100px;
    margin-bottom: -100px;
  }

  #introduction {
    font-weight: 700;
    font-size: 3vh;
  }

  #restart {
    width: 150px;
    height: 150px;
    font-weight: 700;
    font-size: 2vh;
  }
  #gameover {
    font-weight: 700;
    font-size: 3vh;
    margin-bottom: 280px;
  }

  #perfect {
    font-weight: 700;
    font-size: 3vh;
  }
  #action {
    width: 150px;
    height: 150px;
    font-weight: 700;
    font-size: 2vh;
  }
  #placar {
    margin-bottom: 170px;
    right: 20px;
    font-size: 2vh;
  }
  #hiscore {
    text-decoration: none;
  }
  #hiscore_h {
    text-decoration: none;
  }
  #saveplacar {
    font-size: 3vh;
  }
  #saveBtn {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    font-weight: 700;
    font-size: 3vh;
    align-items: center;
  }
  #nameSave {
    font-size: 3vh;
  }
  #titulo {
    margin-bottom: 400px;
    font-weight: 700;
    font-size: 4vh;
  }

  .flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
  }

  .content {
    position: relative;
    height: 20vh;
  }
}
